import React from 'react';
import { IconSvg } from './icon-props';

export const IconSearch = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '24.034'}
      height={props.height || '24.034'}
      viewBox="0 0 24.034 24.034"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_327" data-name="Group 327" transform="translate(-196.978 -902.057)">
        <circle
          id="Ellipse_36"
          data-name="Ellipse 36"
          cx="8.541"
          cy="8.541"
          r="8.541"
          transform="translate(198.442 903.521)"
          fill="none"
          stroke="#061e3b"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.928"
        />
        <line
          id="Line_37"
          data-name="Line 37"
          x2="5.917"
          y2="5.919"
          transform="translate(213.024 918.1)"
          fill="none"
          stroke="#061e3b"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.928"
        />
      </g>
    </IconSvg>
  );
};
