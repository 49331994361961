import { Button, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { TABS_COLOR } from '../colors';

export interface TabsProps {
  activeTabIndex: number;
  tabs: { label: string; value: string }[];
  handleActiveTab: (value: number) => void;
}

export default function Tabs(props: TabsProps) {
  const theme = useTheme();
  const { activeTabIndex, tabs, handleActiveTab } = props;
  console.log(activeTabIndex);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {tabs.map((tab, index) => (
        <Button
          key={tab.value}
          onClick={() => handleActiveTab(index)}
          sx={{
            border: `2px solid transparent`,
            borderColor: activeTabIndex === index ? theme.palette.primary.main : 'transparent',
            borderRadius: '50px',
            color: activeTabIndex === index ? theme.palette.secondary.main : TABS_COLOR,
            textTransform: 'none',
            padding: '15px 35px',
            marginLeft: index != 0 ? '10px' : '0px',
            [theme.breakpoints.down('sm')]: {
              padding: '10px 20px',
              fontSize: '14px'
            }
          }}
          variant="text"
        >
          {tab.label}
        </Button>
      ))}
    </Box>
  );
}
