import { Box, Button, InputBase, useTheme } from '@mui/material';
import React from 'react';
import { IconSearch } from '../assets/icons/search';
import { HideAtMd } from './primitives/hide-at-md';

export interface SearchInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  setClicked:  (value: boolean) => void
}

export default function SearchInput(props: SearchInputProps) {
  const { value, placeholder, onChange, setClicked } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        alignItems: 'center',
        bgcolor: 'white',
        borderRadius: '40px',
        display: 'flex',
        width: '100%',
        padding: '11px'
      }}
    >
      <HideAtMd>
        <IconSearch
          fill={theme.palette.secondary.main}
          height="24px"
          width="24px"
          marginLeft="15px"
          marginRight={'10px'}
        />
      </HideAtMd>

      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
      />
      <Button
        sx={{
          borderRadius: '50px',
          color: 'white',
          textTransform: 'none',
          padding: '15px 35px',
          [theme.breakpoints.down('md')]: {
            padding: '10px 20px'
          }
        }}
        variant="contained"
        onClick = {()=> {setClicked(true)}}
      >
        Search
      </Button>
    </Box>
  );
}
