import { Box } from '@mui/material';
import React from 'react';

export interface AgencyLogoProps {
  agencyColor: string;
  agencyLogo: string;

  containerStyle?: React.CSSProperties;
}

export default function AgencyLogo(props: AgencyLogoProps) {
  const { agencyColor, agencyLogo, containerStyle } = props;
  return (
    <Box
      bgcolor={agencyColor}
      sx={{
        height: containerStyle?.height || '40px',
        width: containerStyle?.width || '152px',
        borderRadius: '21px',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        paddingX: '15px'
      }}
      style={{ ...containerStyle }}
    >
      <img
        src={agencyLogo}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          objectPosition: 'center'
        }}
      />
    </Box>
  );
}
